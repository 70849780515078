import { createRouter, createWebHashHistory } from 'vue-router'
import Perk from '../views/PerkNps.vue'
import UzbechkaSaratov7Nps from '../views/UzbechkaSaratov7Nps.vue'
// import UzbechkaSaratov7NpsNew from '../views/UzbechkaSaratov8NpsNew.vue'
import UzbechkaSaratov8Nps from '../views/UzbechkaSaratov8Nps.vue'
// import UzbechkaSaratov8NpsNew from '../views/UzbechkaSaratov8NpsNew.vue'
import UzbechkaPenza17Nps from "../views/UzbechkaPenza17Nps.vue";
// import UzbechkaPenza17NpsNew from '../views/UzbechkaPenza17NpsNew.vue'
import UzbechkaPenza26Nps from '../views/UzbechkaPenza26Nps.vue'
// import UzbechkaPenza26NpsNew from '../views/UzbechkaPenza26NpsNew.vue'
import Djos from '../views/DjosNps.vue'
import Hvanch from '../views/HvanchNps.vue'
import HvanchVolgograd from '../views/HvanchVolgogradNps.vue'

const routes = [
  {
    path: '/',
    name: 'DJOS',
    component: Djos
  },
  {
    path: '/hvanch_28',
    name: 'HVANCH',
    component: HvanchVolgograd
  },
  {
    path: '/hvanch',
    name: 'HVANCH28',
    component: Hvanch
  },
  {
    path: '/perk',
    name: 'PERK',
    component: Perk
  },
  {
    path: '/uzbechka_saratov_7',
    name: 'UZBECHKA_SAR_7',
    component: UzbechkaSaratov7Nps
  },
  {
    path: '/uzbechka_saratov_8',
    name: 'UZBECHKA_SAR_8',
    component: UzbechkaSaratov8Nps
  },
  {
    path: '/uzbechka_penza_17',
    name: 'UZBECHKA_PENZA_17',
    component: UzbechkaPenza17Nps
  },
  {
    path: '/uzbechka_penza_26',
    name: 'UZBECHKA_PENZA_26',
    component: UzbechkaPenza26Nps
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router